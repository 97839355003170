<template>
  <b-card
    class="overflow-visble"
    no-body
  >
    <b-table
      striped
      responsive
      :items="users"
      :fields="fields"
    >
      <template #cell(name)="data">
        <router-link
          :to="{ name: 'user', params: { id: data.item.id } }"
        >
          <b-media
            no-body
            class="align-items-center"
          >
            <a class="rounded-circle mr-1">
              <b-img
                alt="User img"
                :src="data.item.logo"
                class="logo avatar"
                :class="data.item.active_status ? 'border-success' : 'border-danger'"
              />
            </a>
            <b-media-body>
              <span class="font-weight-600 name mb-0 text-sm">
                {{ data.item.name }}
              </span>
            </b-media-body>
          </b-media>
        </router-link>
      </template>
      <template #cell(active_status)="data">
        <b-badge
          pill
          :variant="(data.item.active_status === true) ? 'success' : 'warning'"
        >
          {{ data.item.active_status | status }}
        </b-badge>
      </template>
      <template #cell(role_name)="data">
        <b-badge variant="primary">{{
          data.item.role_name | role_name
        }}</b-badge>
      </template>
      <template #cell(tools)="data">
        <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >

          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <b-dropdown-item :to="{ name: 'UsersEditForm', params: { id: data.item.id } }">
            <feather-icon icon="EditIcon" />
            <span class="align-middle ml-50">Editar usuario</span>
          </b-dropdown-item>
          <b-dropdown-item
            @click="showDeleteConfirmationModal(data.item)"
          >
            <feather-icon icon="Trash2Icon" />
            <span class="align-middle ml-50">Eliminar usuario</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
    <b-row class="mt-3 d-flex justify-content-end">
      <b-col sm="auto">
        <b-pagination
          v-if="pagination.total_objects > pagination.per_page"
          v-model="pagination.page"
          first-number
          last-number
          :total-rows="pagination.total_objects"
          :per-page="pagination.per_page"
          @change="
            value => {
              getUsers({ page: value})
            }
          "
        />
      </b-col>
      <b-col sm="auto">
        <em
          v-if="loading"
          class="fas fa-spinner fa-spin"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BBadge, BTable, BMedia, BMediaBody, BImg, BDropdown, BDropdownItem, BPagination, BRow, BCol,
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BBadge,
    BTable,
    BMedia,
    BMediaBody,
    BImg,
    BDropdown,
    BDropdownItem,
    BPagination,
    BRow,
    BCol,
  },
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: 'Nombre',
        },
        {
          key: 'active_status',
          label: 'Estado',
        },
        {
          key: 'email',
          label: 'Email',
        },
        {
          key: 'role_name',
          label: 'Rol',
        },
        {
          key: 'tools',
          label: 'herramientas',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('users', ['users', 'pagination', 'loading']),
    ...mapGetters('auth', ['currentUser']),
  },
  methods: {
    ...mapActions('users', ['getUsers', 'editUser']),
    showDeleteConfirmationModal(user) {
      this.$bvModal.msgBoxConfirm('Porfavor confirma que deseas eliminar el usuario actual', {
        title: 'Mensaje de confirmación',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'SÍ',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          if (value === true) {
            const userToDelete = {
              ...user,
              active_status: false,
            }
            this.editUser(userToDelete)
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Usuario eliminado',
                    icon: 'Trash2Icon',
                    variant: 'success',
                  },
                })
                this.getUsers({ by_active_status: 'all' })
              })
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.avatar{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
}
.logo {
  border: 3px solid;
}
.border-success {
  border-color: #2dce89;
}
.border-danger {
  border-color: #f5365c;
}
</style>
