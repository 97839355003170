<template>
  <div>
    <b-row>
      <b-col class="d-flex justify-content-between pb-1 align-items-center">
        <h3 class="font-weight-bolder">
          Usuarios
          <b-badge variant="dark">
            {{ pagination.total_objects }}
          </b-badge>
        </h3>
        <b-button
          :to="{ name:'UsersForm' }"
          variant="primary"
        >
          Alta de usuario
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <users-table />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BBadge,
  BButton,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import UsersTable from '@/@core/components/users/UsersTable.vue'

export default {
  components: {
    BRow,
    BCol,
    BBadge,
    BButton,
    UsersTable,
  },
  computed: {
    ...mapGetters('users', ['pagination']),
  },
  mounted() {
    this.getUsers({ by_active_status: 'all' })
  },
  methods: {
    ...mapActions('users', ['getUsers']),
  },
}
</script>

<style lang="scss" scoped>

</style>
